import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { NgxCommonsModule } from '@cybexer/ngx-commons';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import { IntroLayoutComponent } from './intro-layout.component';

@NgModule({
  declarations: [IntroLayoutComponent],
  imports: [
    RouterModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    SharedComponentsModule,
    NgxCommonsModule,
    CommonModule,
    MatListModule,
  ],
  exports: [MatInputModule, MatCardModule, MatButtonModule, MatSidenavModule, MatToolbarModule],
})
export class IntroLayoutModule {}
