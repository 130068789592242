import { Pipe, PipeTransform } from '@angular/core';
import { NiceDictionary } from '../../models';

@Pipe({
  name: 'isaNiceDescription',
})
export class NiceDescriptionPipe implements PipeTransform {
  niceDictionary = NiceDictionary;

  constructor() {}

  transform(value: string, categoryName: string) {
    return this.niceDictionary.categories
      .find((category) => category.name === categoryName)
      .items.find((it) => it.id.toLowerCase() === value.toLowerCase()).value;
  }
}
