<div class="isa-layout">
  <header class="isa-header">
    <mat-toolbar class="isa-toolbar">ISA</mat-toolbar>
  </header>
  <main class="isa-content">
    <mat-sidenav-container class="isa-content-body">
      <router-outlet></router-outlet>
    </mat-sidenav-container>
  </main>
  <isa-footer class="isa-footer"></isa-footer>
</div>
