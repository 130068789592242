import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { FileInfo } from '../../models';
import { IsaFeature } from '../../models/shared/features.model';
import { IsaModule } from '../../models/shared/isa-module.model';
import { SentrySettings } from '../../models/shared/sentry-settings.model';
import {
  ApiToken,
  CombinedObserverSettings,
  ISettings,
  Settings,
  SigningKey,
} from '../../models/shared/settings.model';

@Injectable()
export class SettingsService {
  static OBSERVER_KEY_LOCALSTORAGE = 'ok';
  settings: Settings;
  features: IsaFeature[];
  settingsChanged = new EventEmitter<Settings>();

  constructor(private http: HttpClient) {}

  getSettings(force = false): Observable<Settings> {
    if (this.settings && !force) {
      return of(this.settings);
    }
    return this.http.get<Settings>('api/settings').pipe(
      map((data) => new Settings(data)),
      tap((fetchedSettings) => (this.settings = fetchedSettings))
    );
  }

  saveSettings(settings: ISettings): Observable<Settings> {
    return this.http.put<Settings>('api/settings', settings).pipe(
      map((data) => new Settings(data)),
      tap((savedSettings) => {
        this.settings = savedSettings;
        this.settingsChanged.emit(savedSettings);
      })
    );
  }

  isModuleEnabled(module: IsaModule): Observable<boolean> {
    return this.getSettings().pipe(map((settings: Settings) => settings.isModuleEnabled(module)));
  }

  generateToken(): Observable<ApiToken> {
    return this.http
      .get<ApiToken>(`api/settings/generate-token`)
      .pipe(map((data) => new ApiToken(data)));
  }

  generateTokenSigningKey(): Observable<SigningKey> {
    return this.http
      .get<SigningKey>('api/settings/generate-signing-key')
      .pipe(map((data) => new SigningKey(data)));
  }

  getObserverSettings(): Observable<CombinedObserverSettings> {
    return this.http
      .get(`api/settings/observer`)
      .pipe(map((data) => new CombinedObserverSettings(data)));
  }

  /**
   * Also stores the given key in `localStorage` if it is correct.
   */
  checkObserverKey(observerKey: string): Observable<boolean> {
    return this.http
      .get<boolean>(`api/settings/check-observer`, {
        params: { observerKey },
      })
      .pipe(
        tap((isCorrect) => {
          if (isCorrect) {
            localStorage.setItem(SettingsService.OBSERVER_KEY_LOCALSTORAGE, observerKey);
          }
        })
      );
  }

  getImageUrl(imageId: string): Observable<string> {
    return this.http
      .get(`api/settings/files/${imageId}`, { responseType: 'blob' })
      .pipe(map((blob) => URL.createObjectURL(blob)));
  }

  uploadImage(image: File): Observable<FileInfo> {
    const formData = new FormData();
    formData.append('file', image);
    return this.http.post('api/settings/images', formData).pipe(map((data) => new FileInfo(data)));
  }

  deleteImage(imageId: string): Observable<boolean> {
    return this.http.delete(`api/settings/files/${imageId}`).pipe(map(() => true));
  }

  getCustomLogoUrl(): Observable<string> {
    return this.http
      .get(`api/settings/custom-logo`, { responseType: 'blob' })
      .pipe(filter((blob) => !!(blob && blob.size)))
      .pipe(map((blob) => URL.createObjectURL(blob)));
  }

  getCustomLabelUrl(): Observable<string> {
    return this.http
      .get(`api/settings/custom-label`, { responseType: 'blob' })
      .pipe(filter((blob) => !!(blob && blob.size)))
      .pipe(map((blob) => URL.createObjectURL(blob)));
  }

  getSentrySettings(): Observable<SentrySettings> {
    return this.http
      .get<SentrySettings>('api/settings/sentry-settings')
      .pipe(map((data) => new SentrySettings(data)));
  }

  getEnabledFeatures(): Observable<IsaFeature[]> {
    if (this.features) {
      return of(this.features);
    }
    return this.http
      .get<IsaFeature[]>('api/settings/features')
      .pipe(tap((fetchedFeatures) => (this.features = fetchedFeatures)));
  }
}
