import { Component } from '@angular/core';

@Component({
  selector: 'isa-intro-layout',
  templateUrl: './intro-layout.component.html',
  styleUrls: ['./intro-layout.component.scss'],
})
export class IntroLayoutComponent {
  constructor() {}
}
